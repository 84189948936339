<template>
  <div id="page-publicApiAccesses-list">
    <k-crud-layout :search.sync="searchQuery">
      <template #header>
        {{ $tc('publicApiAccess.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="createDialog = true"
        >
          {{ $t('actions.createResource', { resource: $tc('publicApiAccess.title', 1) }) }}
        </v-btn>
        <PublicApiAccessForm
          v-model="createDialog"
          :request="createRequest"
          :title="$tc('publicApiAccess.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <v-alert
          v-if="recentlyCreatedToken"
          type="success"
          outlined
          tile
        >
          {{ $t('publicApiAccess.messages.created', { token: recentlyCreatedToken }) }}
          <v-btn
            class="float-right"
            color="success"
            outlined
            tile
            @click="recentlyCreatedToken = ''"
          >
            {{ $t('actions.close') }}
          </v-btn>
        </v-alert>
        <KCrudTable
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          :search="searchQuery"
          language-prefix="publicApiAccess.fields"
          resource="publicApiAccess"
          @click:edit="openUpdate"
        />
      </template>
    </k-crud-layout>
    <PublicApiAccessForm
      v-model="updateDialog"
      :request="updateRequest"
      :title="$tc('publicApiAccess.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import PublicApiAccess from '@/application/models/PublicApiAccess.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/publicApiAccess/api/publicApiAccess.js';
import PublicApiAccessForm from '@/modules/publicApiAccess/components/PublicApiAccessForm.vue';

export default {
  name: 'PublicApiAccessIndex',
  components: {
    KCrudTable,
    PublicApiAccessForm,
    KCrudLayout,
  },
  data() {
    return {
      recentlyCreatedToken: '',
      searchQuery: '',
      updateDialog: false,
      createDialog: false,
      updateFormValues: new PublicApiAccess(),
      createFormValues: new PublicApiAccess(),
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'name',
        },
        {
          value: 'token',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'settings.public-api-access' },
        text: this.$tc('publicApiAccess.title', 2),
      },
    ]);
  },
  methods: {
    updateRequest() {
      return update(this.updateFormValues);
    },
    async createRequest() {
      const response = await create(this.createFormValues);
      if (response.data?.data?.token) {
        this.recentlyCreatedToken = response.data.data.token;
      }
      return response;
    },
    indexRequest() {
      return index(...arguments);
    },
    async openUpdate(item) {
      this.updateFormValues = new PublicApiAccess();
      const publicApiAccessShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(publicApiAccessShowRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
