import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (...args) => getPaginated('api-token', ...args);

export const create = (publicApiAccess) => post('api-token', publicApiAccess);

export const show = (publicApiAccessId) => get(`api-token/${publicApiAccessId}`);

export const update = (publicApiAccess) => put(`api-token/${publicApiAccess.id}`, publicApiAccess);

export const remove = (publicApiAccessId) => destroy(`api-token/${publicApiAccessId}`);
